import { ChangeEvent, ReactNode } from "react"

import { cn } from "@/lib/utils"

export function RadioButton<TVal extends string | number>(props: {
  name: string
  value: TVal
  checked?: boolean
  label: ReactNode
  onChange(ev: ChangeEvent<HTMLInputElement>): void
}) {
  return (
    <label className="flex items-center gap-2">
      <input
        name={props.name}
        type="radio"
        checked={props.checked}
        className="hidden"
        onChange={props.onChange}
        value={props.value}
      />
      <div
        className={cn(
          "relative flex h-5 w-5 items-center justify-center rounded-full border",
          props.checked ? "border-transparent" : "border-muted-foreground"
        )}
      >
        <div
          className={cn(
            "ease duration-250 absolute box-content h-full w-full rounded-full border border-laaxred bg-laaxred transition-transform",
            props.checked ? "scale-1 opacity-1" : "scale-0 opacity-0"
          )}
        />
        <div
          className={cn(
            "ease duration-250 absolute h-full w-full rounded-full bg-background transition-transform",
            props.checked ? "scale-50" : "scale-0"
          )}
        />
      </div>
      {typeof props.label === "string" ? (
        <span className="text-sm">{props.label}</span>
      ) : (
        props.label
      )}
    </label>
  )
}
