import { useAutoAnimate } from "@formkit/auto-animate/react"
import { t } from "i18next"
import { ComponentProps, SetStateAction, useRef } from "react"

import { ListItem } from "@/components/common/list-item"
import { QuantitySelector } from "@/components/common/quantity-selector"
import { Eat_Menu_Deal_Group } from "@/lib/__generated__/graphql"
import { cn, rappenToFrancs } from "@/lib/utils"

import { ModifierSingleSelector } from "./modifier-single-selector"

export type DealGroupSelection = {
  sku: string
  items: DealGroupSelectionItems[]
}

export type DealGroupSelectionItems = {
  sku: string
  quantity: number
  modifierGroups: {
    name: string
    id: string
    modifiers: { id: string; name: string }[]
  }[]
}

export type DealSelection = Record<string, DealGroupSelection[]>

export function DealGroup({
  group,
  selection,
  setSelection,
  ...props
}: {
  group: Eat_Menu_Deal_Group
  selection: DealGroupSelectionItems[]
  setSelection(selection: SetStateAction<DealGroupSelectionItems[]>): void
} & ComponentProps<"div">) {
  const instructionRef = useRef<HTMLDivElement>(null)

  const [animationParent] = useAutoAnimate({
    duration: 125,
    easing: "linear",
  })
  return (
    <div {...props}>
      <div className="mt-4">
        <h2 className="text-xl font-bold leading-6">{group.name}</h2>
        <span>
          {t("Combo item - Choose for option", {
            defaultValue: "Pick your option:",
          })}
        </span>
      </div>
      {group.items.map((item, index) => {
        const selectOption = selection?.find(
          (selection) => selection.sku === item.sku
        )

        const isHighlighted = !!selectOption && selectOption.quantity > 0

        const priceText =
          item.priceRappen === 0 ? (
            t("Price - Included", "Included")
          ) : (
            <span className={cn(isHighlighted && "text-primary")}>
              {isHighlighted ? "+ " : ""}
              {rappenToFrancs(
                item.priceRappen * (selectOption?.quantity || 1)
              )}{" "}
              CHF
            </span>
          )

        const style = { animationDelay: `${index * 30}ms` }

        if (group.multiSelectionPermitted) {
          const setQuantity = (quantity: number) => {
            return setSelection((selection) => {
              if (!selection) {
                return [{ sku: item.sku, quantity, modifierGroups: [] }]
              }

              const index = selection?.findIndex(
                (option) => option.sku === selectOption?.sku
              )
              if (index === -1) {
                return selection.concat([
                  { sku: item.sku, quantity, modifierGroups: [] },
                ])
              }
              const current = selection[index]
              current && (current.quantity = quantity)
              return [...selection].filter(Boolean)
            })
          }

          return (
            <div key={item.sku} style={style}>
              <ListItem
                {...item}
                priceText={priceText}
                highlighted={Boolean(selectOption?.quantity)}
                onImageClick={() =>
                  setQuantity(
                    !selectOption || selectOption.quantity === 0
                      ? 1
                      : selectOption.quantity
                  )
                }
              >
                <QuantitySelector
                  quantity={selectOption?.quantity ?? 0}
                  onDecrement={() =>
                    setQuantity((selectOption?.quantity ?? 0) - 1)
                  }
                  onIncrement={() =>
                    setQuantity((selectOption?.quantity ?? 0) + 1)
                  }
                />
              </ListItem>
            </div>
          )
        }
        return (
          <div key={item.sku} style={style}>
            <a
              onClick={() => {
                setSelection([
                  { sku: item.sku, quantity: 1, modifierGroups: [] },
                ])
                setTimeout(() => {
                  instructionRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                  })
                }, 50)
              }}
            >
              <ListItem
                {...item}
                highlighted={isHighlighted}
                priceText={priceText}
              />
            </a>
            <div ref={animationParent}>
              {isHighlighted && (
                <ModifierSingleSelector
                  ref={instructionRef}
                  modifierGroups={item.modifierGroups}
                  onModifiersSelected={(instructions) => {
                    return setSelection((val) =>
                      val.map((selection) =>
                        selection.sku === item.sku
                          ? { ...selection, modifierGroups: instructions }
                          : selection
                      )
                    )
                  }}
                  selectedModifiers={selectOption.modifierGroups}
                />
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}
