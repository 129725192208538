import { useMainStore } from "@/stores/main-store"

import FocusDeal from "./focus-deal"
import FocusSingleItem from "./focus-single-item"

export function FocusEntry(props: { restaurantId: string; id: string }) {
  const [cart] = useMainStore((state) => [state.cart, state.setCart])

  const cartEntry = cart?.entries.find((cart) => cart.id === props.id)

  if (!cartEntry) {
    return null
  }

  return cartEntry.__typename === "Eat_Cart_Item" ? (
    <FocusSingleItem cartEntry={cartEntry} restaurantId={props.restaurantId} />
  ) : (
    <FocusDeal cartEntry={cartEntry} restaurantId={props.restaurantId} />
  )
}
