import { Clock9, User, Utensils } from "lucide-react"
import { useState } from "react"
import { toast } from "sonner"

import { createTableOrder } from "@/actions/create-table-order"
import { startWebPayment } from "@/actions/start-web-payment"
import OrderFooter from "@/components/common/order-footer"
import { Drawer, DrawerContent, DrawerTrigger } from "@/components/ui/drawer"
import { RadioGroup } from "@/components/ui/radio-group"
import { Separator } from "@/components/ui/separator"
import { useTranslation } from "@/i18n/client"
import { SupportedPaymentType } from "@/lib/__generated__/graphql"
import { useLocalStorageState } from "@/lib/hooks"
import { useParamsRequired } from "@/lib/navigation"
import { getAnonId } from "@/lib/utils"
import { useMainStore } from "@/stores/main-store"
import { paymentsConfig } from "@/types/types"

import CartEntry from "./components/cart-entry-checkout"
import PaymentSelectItem from "./components/payment-select-item"

export default function CheckoutStartPage() {
  const { restaurantId } = useParamsRequired("restaurantId")

  if (!restaurantId) {
    throw new Error("No restaurantId in path")
  }

  const { t } = useTranslation()

  const { cart, restaurant, nameOnReceipt, tableNumber } = useMainStore(
    (state) => ({
      cart: state.cart,
      menu: state.menu,
      restaurant: state.restaurant,
      nameOnReceipt: state.nameOnReceipt,
      tableNumber: state.tableNumber,
    })
  )

  const [selectedPaymentType, setSelectedPaymentType] = useLocalStorageState(
    "CREDIT_CARD" as SupportedPaymentType,
    "preferred-payment-type",
    (savedValue) => paymentsConfig.some((config) => config.id === savedValue)
  )

  const [isPaymentDrawerOpen, setIsPaymentDrawerOpen] = useState(false)

  const selectedPayment = paymentsConfig.find(
    (payment) => payment.id == selectedPaymentType
  )

  const [isLoadingStartWebCheckout, setIsLoadingStartWebCheckout] =
    useState(false)

  const startCheckout = async () => {
    if (!cart) {
      toast.error(t("Toast - Cart not found", "Cart not found"))
      return
    }

    setIsLoadingStartWebCheckout(true)
    const order = await createTableOrder({
      anonId: getAnonId(),
      input: {
        entriesTotalRappen: cart.entriesTotalPoints,
        nameOnReceipt,
        restaurantId: restaurantId,
        tableNumber: tableNumber.toString(),
        tipRappen: 0, // Todo: add tip
      },
    })

    if (!order) {
      setIsLoadingStartWebCheckout(false)
      toast.error("Failed to create order")
      return
    }

    const { paymentPageUrl } = await startWebPayment({
      input: {
        anonId: getAnonId(),
        orderId: order.id,
        paymentType: selectedPaymentType,
        returnUrl: `${origin}/${restaurantId}/checkout/pending?orderId=${order.id}`,
      },
    })

    if (!paymentPageUrl) {
      toast.error("Failed to start checkout")
      setIsLoadingStartWebCheckout(false)
      return
    }

    window.location.href = paymentPageUrl
  }

  if (cart === null || restaurant === null) {
    return null
  }

  return (
    <>
      <main className="mt-14 flex flex-col gap-5">
        <div className="flex flex-col gap-3 px-5">
          <h1 className="text-xl font-bold">
            {t("Checkout - Page title", "Order Overview")}
          </h1>

          <div className="flex flex-col gap-3">
            <div className="flex items-center gap-3">
              <Utensils className="size-5" />

              <div className="flex flex-col">
                <p className="text-sm font-semibold">{restaurant.name}</p>
              </div>
            </div>

            <div className="flex items-center gap-3">
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_582_46087)">
                  <path d="M4.5 7V17ZM1.5 7H23.5ZM4.5 10H20.5ZM20.5 7V17Z" />
                  <path
                    d="M4.5 7V17M1.5 7H23.5M4.5 10H20.5M20.5 7V17"
                    stroke="#000"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_582_46087">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <p className="text-sm font-semibold">
                {t("Checkout - Table", "Table")} {tableNumber}
              </p>
            </div>

            <div className="mt-1 flex items-center gap-3">
              <User className="size-5" />
              <p className="text-sm font-semibold">{nameOnReceipt}</p>
            </div>
          </div>
        </div>

        {restaurant.waitingTimeMinutes >= 20 && (
          <>
            <Separator className="h-0.5 bg-gray-100" />
            <div className="flex items-center gap-3 px-5">
              <Clock9 className="h-6 w-6 fill-yellow-400 text-white" />
              <p className="text-sm font-semibold">
                {t(
                  "Checkout - Expected waiting time",
                  "Expected waiting time {{waitingTimeMinutes}} min",
                  { waitingTimeMinutes: restaurant.waitingTimeMinutes }
                )}
              </p>
            </div>

            <Separator className="h-0.5 bg-gray-100" />
          </>
        )}

        <div className="flex flex-col gap-3 px-5">
          <h1 className="text-xl font-bold">
            {t("Checkout - Order summary section title", "Order summary")}
          </h1>

          {cart.entries.map((cartEntry) => (
            <CartEntry key={cartEntry.id} cartEntry={cartEntry} />
          ))}
        </div>

        <Separator className="h-0.5 bg-gray-100" />

        <div className="flex flex-col gap-3 px-5">
          <h1 className="text-xl font-bold">
            {t("Checkout - Payment", "Payment")}
          </h1>

          <div className="rounded-md border p-2">
            <Drawer
              open={isPaymentDrawerOpen}
              onOpenChange={setIsPaymentDrawerOpen}
            >
              <DrawerTrigger asChild>
                <div className="flex w-full items-center justify-between hover:cursor-pointer">
                  <div className="flex items-center gap-3">
                    {selectedPayment ? (
                      <img
                        src={selectedPayment.imageUrl}
                        alt={selectedPayment.name}
                        className="h-6"
                      />
                    ) : (
                      <></>
                    )}
                    <p className="shrink-0 text-sm">
                      {
                        paymentsConfig.find(
                          (payment) => payment.id === selectedPaymentType
                        )?.name
                      }
                    </p>
                  </div>

                  <p className="text-xs text-blue-600">
                    {t("Checkout - Change payment", "change")}
                  </p>
                </div>
              </DrawerTrigger>

              <DrawerContent className="px-3 pb-10">
                <RadioGroup
                  value={selectedPaymentType}
                  onValueChange={(value) => {
                    setSelectedPaymentType(value as SupportedPaymentType)
                    setIsPaymentDrawerOpen(false)
                  }}
                  className="flex flex-col gap-6 pt-4"
                >
                  {paymentsConfig.map((paymentConfig) => (
                    <PaymentSelectItem
                      key={paymentConfig.id}
                      paymentConfig={paymentConfig}
                      setSelectedPaymentType={setSelectedPaymentType}
                      setIsPaymentDrawerOpen={setIsPaymentDrawerOpen}
                    />
                  ))}
                </RadioGroup>
              </DrawerContent>
            </Drawer>

            <Separator className="my-2 h-0.5 bg-gray-100" />

            <p className="text-sm">
              {t(
                "Checkout - Informations redirection to saferpay",
                "On checkout, you'll be redirected to a secure payment page to enter your payment details."
              )}
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center">
          <p className="max-w-xs text-center text-sm">
            {t(
              "Checkout - Terms and contitions 1",
              "By continuing, you agree to the"
            )}
            <br />
            <a
              href="https://laax.com"
              target="_blank"
              className="text-blue-700"
            >
              {t("Checkout - Terms and contitions 2", "terms and conditions")}
            </a>
            {t("Checkout - Terms and contitions 3", ".")}
          </p>
        </div>
      </main>
      <OrderFooter
        stepNumber={4}
        buttonText={t("Cart summary - Button - At details page", "To checkout")}
        onButtonClick={startCheckout}
        buttonDisabled={isLoadingStartWebCheckout}
      />
    </>
  )
}
