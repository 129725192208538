import { t } from "i18next"
import { useState } from "react"
import Markdown from "react-markdown"

import { addItemInCart } from "@/actions/cart/add-item"
import {
  ModifierSingleSelector,
  SelectedModifiers,
} from "@/app/shared-components/modifier-single-selector"
import { useDrawerContext } from "@/components/common/drawer/drawer-layout"
import { DrawerNavigationHeader } from "@/components/common/drawer/drawer-nav-header"
import { Button } from "@/components/ui/button"
import { DrawerContent, DrawerFooter } from "@/components/ui/drawer"
import { Eat_Menu_Item } from "@/lib/__generated__/graphql"
import { rappenToFrancs, wait } from "@/lib/utils"
import { useMainStore } from "@/stores/main-store"

export function FocusSingleItem(props: {
  item: Eat_Menu_Item
  restaurantId: string
}) {
  const cart = useMainStore((state) => state.cart)
  const cartItem = cart?.entries.find((item) => item.sku === item.sku)

  const setCart = useMainStore((state) => state.setCart)

  const drawer = useDrawerContext()

  const [selectedModifiers, setSelectedModifiers] = useState<
    SelectedModifiers[]
  >([])

  const modifiersSelected =
    selectedModifiers.length === (props.item.modifierGroups?.length ?? 0)

  const [currentlyAddingItem, setCurrentlyAddingItem] = useState(false)

  return (
    <DrawerContent className="overflow-hidden" disableOverlay>
      <DrawerNavigationHeader title="" close />
      <div className="mx-auto">
        {props.item.imageUrl ? (
          <img
            src={props.item.imageUrl || ""}
            alt={props.item.name}
            className="h-36 w-36 rounded-md object-cover"
          />
        ) : (
          <div className="h-36 w-36 rounded-md bg-muted" />
        )}
      </div>
      <div className="flex-col gap-1 p-4">
        <div className="flex justify-between">
          <h1 className="text-lg font-bold">{props.item.name}</h1>
          <p>
            {props.item.priceRappen
              ? rappenToFrancs(props.item.priceRappen) + " CHF"
              : "-- CHF"}
          </p>
        </div>
        <p className="text-muted-foreground">
          <Markdown>{props.item.description}</Markdown>
        </p>
        <div className="mt-8">
          <ModifierSingleSelector
            modifierGroups={props.item.modifierGroups}
            onModifiersSelected={setSelectedModifiers}
            selectedModifiers={selectedModifiers}
          />
        </div>
      </div>

      <DrawerFooter>
        <Button
          disabled={!modifiersSelected}
          variant={modifiersSelected ? "default" : "outline"}
          className="flex h-12 w-full gap-2 transition-transform duration-100 active:scale-105"
          onClick={async () => {
            if (!props.item || currentlyAddingItem) return

            setCurrentlyAddingItem(true)

            wait(50).then(() => drawer.close())

            const newCart = await addItemInCart({
              quantity: 1,
              restaurantId: props.restaurantId,
              itemSku: props.item.sku,
              modifierGroups: selectedModifiers,
            })

            setCart(newCart)
          }}
        >
          <img
            className="h-5 w-5"
            src="/icons/cart.svg"
            style={{ filter: "invert(1)" }}
          />
          {t("Menu - Item drawer - Button add cart", "Add to your cart")}
        </Button>
      </DrawerFooter>
    </DrawerContent>
  )
}
