import { toast } from "sonner"

import { graphql } from "@/lib/__generated__"
import { Eat_Menu } from "@/lib/__generated__/graphql"
import apolloClient from "@/lib/apollo/apollo-client"

export async function getMenuForRestaurant(
  restaurantId: string
): Promise<Eat_Menu | null> {
  try {
    const { data } = await apolloClient.query({
      query: graphql(`
        query Eat_Menu_GetMenuForRestaurant(
          $input: Eat_Menu_GetMenuForRestaurant_Input!
        ) {
          Eat_Menu_GetMenuForRestaurant(input: $input) {
            menu {
              id
              sections {
                entries {
                  ... on Eat_Menu_Deal {
                    basePricePoints
                    basePriceRappen
                    description
                    groups {
                      description
                      items {
                        description
                        id
                        imageUrl
                        menuSectionId
                        name
                        pricePoints
                        priceRappen
                        sku
                        modifierGroups {
                          id
                          modifiers {
                            id
                            name
                          }
                          multiSelectionPermitted
                          name
                        }
                      }
                      maximumSelection
                      minimumSelection
                      multiSelectionPermitted
                      mustSelectAnItem
                      name
                      sku
                    }
                    id
                    imageUrl
                    menuSectionId
                    name
                    sku
                  }
                  ... on Eat_Menu_Item {
                    description
                    id
                    imageUrl
                    menuSectionId
                    modifierGroups {
                      id
                      modifiers {
                        id
                        name
                      }
                      multiSelectionPermitted
                      name
                    }
                    name
                    pricePoints
                    priceRappen
                    sku
                  }
                }
                id
                name
              }
            }
          }
        }
      `),
      variables: {
        input: {
          restaurantId,
        },
      },
    })

    return data.Eat_Menu_GetMenuForRestaurant.menu
  } catch (e) {
    toast.error("Menu not found")
    return null
  }
}
