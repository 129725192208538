import { format } from "date-fns/format"
import { X } from "lucide-react"
import { useState } from "react"

import { Receipt } from "@/components/common/receipt/receipt"
import { useTranslation } from "@/i18n/client"
import { Eat_Order_Table } from "@/lib/__generated__/graphql"
import { cn } from "@/lib/utils"
import { useMainStore } from "@/stores/main-store"

import { ViewMenuButton } from "./view-menu-button"

export function YourOrders(props: {
  restaurantId: string
  orders: Eat_Order_Table[]
}) {
  const { t } = useTranslation()

  const { restaurant, tableNumber } = useMainStore((state) => ({
    restaurant: state.restaurant,
    tableNumber: state.tableNumber,
  }))

  const [showOrderIndex, setShowOrderIndex] = useState<number | null>(null)

  if (!restaurant) {
    return null
  }

  const order = showOrderIndex && props.orders[showOrderIndex]

  if (order) {
    return (
      <div className="relative flex flex-col items-center">
        <button
          className="absolute right-0 top-0 px-1.5 py-3"
          onClick={() => {
            setShowOrderIndex(null)
          }}
        >
          <X strokeWidth={1.75} />
        </button>

        <Receipt
          expandReceipt={true}
          restaurantName={order.restaurantName}
          tableNumber={order.tableNumber}
          orderNumber={order.orderNumber}
          nameOnReceipt={order.nameOnReceipt}
          createdAt={order.createdAt}
          items={order.items}
          totalPriceRappen={order.itemsTotalRappen}
          points={0}
        />
      </div>
    )
  }

  return (
    <div className="flex h-full flex-col items-center justify-between">
      <div className="flex flex-col items-center">
        <p className="mb-5 text-3xl font-semibold">{restaurant.name}</p>

        <div className="flex flex-col items-center gap-3">
          <p className="text-center text-sm text-muted-foreground">
            {t(
              "Home - Thank you for your order and show table number",
              "Thank you for your order!"
            )}
          </p>

          <div className="flex items-center gap-1">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_582_46087)">
                <path d="M4.5 7V17ZM1.5 7H23.5ZM4.5 10H20.5ZM20.5 7V17Z" />
                <path
                  d="M4.5 7V17M1.5 7H23.5M4.5 10H20.5M20.5 7V17"
                  stroke="#000"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_582_46087">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>

            <p>
              {t("Home - Table", "Table")} {tableNumber}
            </p>
          </div>

          <div className="flex max-h-48 w-full flex-col items-center gap-3 overflow-y-scroll px-5">
            {props.orders.map((order, index) => {
              return (
                <button
                  key={index}
                  className={cn(
                    "flex w-full items-center justify-between rounded-xl p-3 text-left text-xs",
                    order.tableOrderStatus === "ORDER_DONE"
                      ? "bg-emerald-100 text-emerald-600"
                      : order.tableOrderStatus === "ORDER_FAILED" ||
                          order.tableOrderStatus === "PAYMENT_FAILED"
                        ? "bg-red-100 text-red-600"
                        : "bg-blue-100 text-blue-600"
                  )}
                  onClick={() => setShowOrderIndex(index)}
                >
                  <div className="flex flex-col">
                    <p className="font-semibold">
                      {t(
                        "Home - Button order infos",
                        "Order from {{time}} Table {{tableNumber}}",
                        {
                          time: format(order.createdAt, "HH:mm"),
                          tableNumber: order.tableNumber,
                        }
                      )}
                    </p>
                    <p>
                      {order.tableOrderStatus === "ORDER_DONE"
                        ? t(
                            "Home - Order delivered",
                            "Your order has been delivered."
                          )
                        : t(
                            "Home - Order in preparation",
                            "Your order is being prepared."
                          )}
                    </p>
                  </div>

                  <div className="rounded-full bg-white p-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M13 9h-2V7a1 1 0 0 0-2 0v2H7a1 1 0 0 0 0 2h2v2a1 1 0 0 0 2 0v-2h2a1 1 0 0 0 0-2zm9.707 12.293l-5.682-5.682A8.948 8.948 0 0 0 19 10c0-4.962-4.038-9-9-9s-9 4.038-9 9 4.038 9 9 9a8.948 8.948 0 0 0 5.611-1.975l5.682 5.682a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414zM10 17c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </button>
              )
            })}
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center gap-3 pt-3">
        <ViewMenuButton restaurantId={props.restaurantId} />
      </div>
    </div>
  )
}
