import { useTranslation } from "@/i18n/client"

import { InstructionSteps } from "./instruction-steps"

export default function MenuHeader(props: {
  restaurantName: string
  infoMarkdown: string
  isOpen: boolean
  openingText: string
}) {
  const { t } = useTranslation()

  return (
    <div className="mt-5">
      <div className="flex items-center justify-between">
        <div className="text-sm">
          {props.isOpen ? (
            <span className="text-green-500">{t("OPEN", "OPEN")}</span>
          ) : (
            <span className="text-red-500">{t("CLOSED", "CLOSED")}</span>
          )}{" "}
          • {props.openingText}
        </div>

        <InstructionSteps />
      </div>
    </div>
  )
}
