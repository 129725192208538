import { Clock9, X } from "lucide-react"
import { useEffect, useState } from "react"

import { useTranslation } from "@/i18n/client"
import { cn, getShowWarningTime } from "@/lib/utils"

export default function MenuWarningBanner(props: {
  waitingTimeMinutes: number
  className?: string
}) {
  const { t } = useTranslation()

  const [showWarningTime, setShowWarningTime] = useState(false)

  useEffect(() => {
    const showWarningTime = getShowWarningTime()
    setShowWarningTime(showWarningTime)
  }, [])

  if (!showWarningTime || props.waitingTimeMinutes < 20) {
    return null
  }

  return (
    <div
      className={cn(
        "relative mb-4 flex w-full gap-3 rounded-2xl border border-black px-2 py-4",
        props.className
      )}
    >
      <Clock9 className="h-6 w-6 fill-yellow-400 text-white" />

      <div className="flex-1">
        <div className="flex w-full justify-between">
          <p className="font-semibold">
            {t("Warning time - Title", "Wow, today is a busy day!")}
          </p>
          <button
            onClick={() => {
              setShowWarningTime(false)
              localStorage.setItem(
                "lastDismissedWarningTime",
                new Date().toISOString()
              )
            }}
          >
            <X className="h-4 w-4" />
          </button>
        </div>

        <p className="text-sm">
          {t(
            "Warning time - Description",
            "Preparation takes about {{waitingTimeMinutes}} minutes.",
            { waitingTimeMinutes: props.waitingTimeMinutes }
          )}
        </p>
      </div>

      <div className="absolute -bottom-[6.5px] left-[40px] h-[1px] w-4 rotate-45 transform rounded-md bg-black" />
      <div className="absolute -bottom-[6.5px] left-[50.8px] h-[1px] w-4 -rotate-45 transform rounded-md bg-black" />
      <div className="absolute -bottom-[1.5px] left-[43.25px] h-[3px] w-[20.4px] transform rounded-md bg-white" />
    </div>
  )
}
