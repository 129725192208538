import { stripTypename } from "@apollo/client/utilities"
import { useCallback, useEffect, useState } from "react"
import Markdown from "react-markdown"

import { removeItemInCart } from "@/actions/cart/remove-item"
import { updateItemInCart } from "@/actions/cart/update-item"
import {
  ModifierSingleSelector,
  SelectedModifiers,
} from "@/app/shared-components/modifier-single-selector"
import { useDrawerContext } from "@/components/common/drawer/drawer-layout"
import { DrawerNavigationHeader } from "@/components/common/drawer/drawer-nav-header"
import { QuantitySelector } from "@/components/common/quantity-selector"
import { Button } from "@/components/ui/button"
import { DrawerContent, DrawerFooter } from "@/components/ui/drawer"
import { useTranslation } from "@/i18n/client"
import { Eat_Cart_Item } from "@/lib/__generated__/graphql"
import { rappenToFrancs } from "@/lib/utils"
import { useMainStore } from "@/stores/main-store"

export default function FocusSingleItem(props: {
  restaurantId: string
  cartEntry: Eat_Cart_Item
}) {
  const { t } = useTranslation()

  const [, setCart] = useMainStore((state) => [state.cart, state.setCart])

  const menuItem = props.cartEntry.menuItem

  const drawer = useDrawerContext()

  const [selection, setSelection] = useState({
    sku: props.cartEntry.sku,
    modifierGroups: stripTypename(
      props.cartEntry.modifierGroups ?? []
    ) as SelectedModifiers[],
    quantity: props.cartEntry.quantity,
  })

  const updateItem = useCallback(
    async (quantity: number, modifierGroups: SelectedModifiers[]) => {
      setSelection((selection) => ({ ...selection, modifierGroups, quantity }))
    },
    []
  )

  //Save cart on unmount by registering/abusing a react effect destructor.
  //Could be made nicer by providing a stable `onDrawerClose` in drawer context.
  useEffect(
    () => () => {
      //setSelection is required to get the latest selection without re-mounting this hook.
      setSelection((selection) => {
        const action =
          selection.quantity === 0
            ? removeItemInCart({
                restaurantId: props.restaurantId,
                id: props.cartEntry.id,
              })
            : updateItemInCart({
                quantity: selection.quantity,
                restaurantId: props.restaurantId,
                id: props.cartEntry.id,
                modifierGroups: stripTypename(selection.modifierGroups),
              })
        action.then((newCart) => setCart(newCart))
        return selection
      })
    },
    []
  )

  return (
    <DrawerContent className="overflow-hidden" disableOverlay>
      <DrawerNavigationHeader title="" close />
      <div className="mx-auto">
        {menuItem.imageUrl ? (
          <img
            src={menuItem.imageUrl || ""}
            alt={menuItem.name}
            className="h-36 w-36 rounded-md object-cover"
          />
        ) : (
          <div className="h-36 w-36 rounded-md bg-muted" />
        )}
      </div>
      <div className="flex-col gap-1 p-4">
        <div className="flex justify-between">
          <h1 className="text-lg font-bold">{menuItem.name}</h1>
          <p>
            {props.cartEntry.priceRappen
              ? rappenToFrancs(
                  props.cartEntry.menuItem.priceRappen * selection.quantity
                ) + " CHF"
              : "-- CHF"}
          </p>
        </div>
        <p className="text-muted-foreground">
          <Markdown>{menuItem.description}</Markdown>
        </p>
        <div className="mt-8 flex flex-col gap-8">
          <QuantitySelector
            quantity={selection.quantity}
            onIncrement={() =>
              updateItem(selection.quantity + 1, selection.modifierGroups ?? [])
            }
            onDecrement={() =>
              updateItem(selection.quantity - 1, selection.modifierGroups ?? [])
            }
          />
          <ModifierSingleSelector
            modifierGroups={menuItem.modifierGroups}
            onModifiersSelected={(selectedModifiers) =>
              updateItem(selection.quantity, selectedModifiers)
            }
            selectedModifiers={selection.modifierGroups ?? []}
          />
        </div>
      </div>
      <DrawerFooter>
        <Button
          className="flex h-12 w-full flex-col gap-1 text-sm"
          onClick={drawer.close}
        >
          {t("Cart - Item drawer - Button confirm", "Confirm")}
        </Button>
      </DrawerFooter>
    </DrawerContent>
  )
}
