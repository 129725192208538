import { toast } from "sonner"

import { graphql } from "@/lib/__generated__"
import {
  Eat_Cart_RemoveDeal_Input,
  FullCartFragmentFragment,
} from "@/lib/__generated__/graphql"
import apolloClient from "@/lib/apollo/apollo-client"
import { getAnonId } from "@/lib/utils"

export async function removeDealInCart(input: Eat_Cart_RemoveDeal_Input) {
  const response = await apolloClient.mutate({
    mutation: graphql(`
      mutation Eat_Cart_RemoveDeal(
        $input: Eat_Cart_RemoveDeal_Input!
        $anonId: ID!
      ) {
        Eat_Cart_RemoveDeal(input: $input, anonId: $anonId) {
          cart {
            ...FullCartFragment
          }
        }
      }
    `),
    variables: {
      input,
      anonId: getAnonId(),
    },
  })
  const newCart = response.data?.Eat_Cart_RemoveDeal.cart
  if (!newCart) {
    toast.error("An error occurred", {
      position: "top-center",
    })
    throw new Error(
      response.errors?.map((err) => err.message).join(", ") ??
        "Could not update cart"
    )
  }

  return newCart as FullCartFragmentFragment
}
