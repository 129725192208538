import { useMainStore } from "@/stores/main-store"

import { FocusDeal } from "./focus-deal"
import { FocusSingleItem } from "./focus-single-item"

export default function FocusEntry(props: {
  itemId: string
  restaurantId: string
}) {
  const menu = useMainStore((state) => state.menu)
  const item = menu?.sections
    .flatMap((section) => section.entries)
    .find((item) => item.sku === props.itemId)

  return item?.__typename === "Eat_Menu_Item" ? (
    <FocusSingleItem item={item} restaurantId={props.restaurantId} />
  ) : item?.__typename === "Eat_Menu_Deal" ? (
    <FocusDeal item={item} restaurantId={props.restaurantId} />
  ) : null
}
