import { ChevronLeft } from "lucide-react"
import { useLocation } from "react-router-dom"

import { useTranslation } from "@/i18n/client"
import { useNavigate } from "@/lib/navigation"
import { cn } from "@/lib/utils"
import { useMainStore } from "@/stores/main-store"

//TODO: Redo this component so it's not this global logic monstronsity
export function Header(props: { restaurantId: string }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const restaurantName = useMainStore((store) => store.restaurant?.name)

  let label = ""

  const isMenuUrl = pathname.includes("/menu")

  if (isMenuUrl) {
    label = restaurantName ?? ""
  } else if (pathname.includes("/cart")) {
    label = t("Header cart", "Cart")
  } else if (pathname.includes("/item")) {
    label = t("Header item", "Item")
  } else if (pathname.includes("/details")) {
    label = t("Header details", "Your details")
  } else if (pathname.includes("/checkout")) {
    label = t("Header checkout", "Checkout")
  }

  let backUrl: string | null = "/"

  if (pathname.includes("/cart")) {
    backUrl = "/menu"
  } else if (pathname.includes("/item")) {
    backUrl = "/cart"
  } else if (pathname.includes("/details")) {
    backUrl = "/cart"
  } else if (pathname.includes("/checkout")) {
    if (
      pathname.includes("/success") ||
      pathname.includes("/failure") ||
      pathname.includes("/pending")
    ) {
      backUrl = null
    } else {
      backUrl = "/details"
    }
  }

  if (label === "") {
    return null
  }

  return (
    <div
      className={cn(
        "fixed left-0 right-0 top-0 z-[20] mb-4 flex justify-center bg-white py-2",
        !isMenuUrl && "shadow-sm"
      )}
    >
      {backUrl && (
        <button
          onClick={() => {
            navigate(`/${props.restaurantId}${backUrl}`)
          }}
        >
          <ChevronLeft className="absolute left-3 top-[0.55rem] size-6" />
        </button>
      )}

      <p className="text-lg font-bold">{label}</p>
    </div>
  )
}
