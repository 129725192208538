import { graphql } from "@/lib/__generated__"
import { Eat_Order_Table } from "@/lib/__generated__/graphql"
import apolloClient from "@/lib/apollo/apollo-client"

export async function listOrdersToday(
  anonId: string
): Promise<Eat_Order_Table[]> {
  const response = await apolloClient.query({
    query: graphql(`
      query Eat_Order_ListOrdersToday($anonId: ID) {
        Eat_Order_ListOrdersToday(anonId: $anonId) {
          orders {
            __typename
            ... on Eat_Order_Table {
              accountIdentifier
              anonId
              commentOnReceipt
              createdAt
              email
              id
              items {
                modifiers {
                  groupName
                  modifierId
                  modifierName
                }
                name
                pricePoints
                priceRappen
                quantity
                sku
                subItems {
                  name
                  quantity
                  sku
                  modifiers {
                    groupName
                    modifierId
                    modifierName
                  }
                }
              }
              itemsTotalPoints
              itemsTotalRappen
              nameOnReceipt
              orderNumber
              orderTotalPoints
              orderTotalRappen
              payment {
                id
              }
              restaurantId
              restaurantName
              tableNumber
              tableOrderStatus
              tipRappen
              userId
            }
          }
        }
      }
    `),
    variables: {
      anonId,
    },
  })

  return response.data.Eat_Order_ListOrdersToday.orders.filter(
    (order) => order.__typename === "Eat_Order_Table"
  ) as Eat_Order_Table[]
}
