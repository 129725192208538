import { graphql } from "@/lib/__generated__"

export const FullCartFragment = graphql(`
  fragment FullCartFragment on Eat_Cart {
    entries {
      ... on Eat_Cart_Deal {
        groups {
          items {
            menuItem {
              description
              id
              imageUrl
              menuSectionId
              modifierGroups {
                id
                modifiers {
                  id
                  name
                }
                multiSelectionPermitted
                name
              }
              name
              pricePoints
              priceRappen
              sku
            }
            modifierGroups {
              id
              modifiers {
                id
                name
              }
              name
            }
            quantity
            sku
          }
          menuDealGroup {
            description
            items {
              description
              id
              imageUrl
              menuSectionId
              name
              pricePoints
              priceRappen
              sku
              modifierGroups {
                id
                modifiers {
                  id
                  name
                }
                multiSelectionPermitted
                name
              }
            }
            maximumSelection
            minimumSelection
            multiSelectionPermitted
            mustSelectAnItem
            name
            sku
          }
          sku
        }
        id
        menuDeal {
          basePricePoints
          basePriceRappen
          description
          groups {
            description
            items {
              description
              id
              imageUrl
              menuSectionId
              modifierGroups {
                id
                modifiers {
                  id
                  name
                }
                multiSelectionPermitted
                name
              }
              name
              pricePoints
              priceRappen
              sku
            }
            maximumSelection
            minimumSelection
            multiSelectionPermitted
            mustSelectAnItem
            name
            sku
          }
          id
          imageUrl
          menuSectionId
          name
          sku
        }
        menuSectionId
        pricePoints
        priceRappen
        quantity
        sku
      }
      ... on Eat_Cart_Item {
        id
        menuItem {
          description
          id
          imageUrl
          menuSectionId
          modifierGroups {
            id
            modifiers {
              id
              name
            }
            multiSelectionPermitted
            name
          }
          name
          pricePoints
          priceRappen
          sku
        }
        menuSectionId
        modifierGroups {
          id
          modifiers {
            id
            name
          }
          name
        }
        pricePoints
        priceRappen
        quantity
        sku
      }
    }
    entriesTotalPoints
    entriesTotalRappen
    id
    restaurantId
  }
`)
