import { toast } from "sonner"

import { graphql } from "@/lib/__generated__"
import apolloClient from "@/lib/apollo/apollo-client"

export async function getRestaurant(restaurantId: string) {
  try {
    const { data } = await apolloClient.query({
      query: graphql(`
        query Eat_Restaurant_GetRestaurant(
          $input: Eat_Restaurant_GetRestaurant_Input!
        ) {
          Eat_Restaurant_GetRestaurant(input: $input) {
            restaurant {
              heroImageUrl
              id
              infoMarkdown
              name
              orderingEnabled
              waitingTimeMinutes
            }
          }
        }
      `),
      variables: {
        input: {
          restaurantId: restaurantId,
        },
      },
    })

    return data.Eat_Restaurant_GetRestaurant.restaurant
  } catch (e) {
    toast.error("Restaurant not found")
    return null
  }
}
