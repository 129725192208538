import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import resourcesToBackend from "i18next-resources-to-backend"
import {
  initReactI18next,
  useTranslation as useTranslationOrg,
} from "react-i18next"

import { getOptions, i18nConfig } from "./i18n.config"

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(
    resourcesToBackend(
      (language: string) => import(`./dictionaries/${language}/eat-web.json`)
    )
  )
  .init({
    ...getOptions(),
    lng: undefined, // let detect the language on client side
    detection: {
      order: ["path", "htmlTag", "cookie", "navigator"],
    },
    preload: typeof window === "undefined" ? i18nConfig.locales : [],
  })

export const useTranslation = useTranslationOrg

i18next.on("languageChanged", (lang) => (document.documentElement.lang = lang))
