import { t } from "i18next"
import { SetStateAction, useCallback, useMemo, useState } from "react"

import { addDealInCart } from "@/actions/cart/add-deal"
import { ContentSlider } from "@/components/common/content-slider"
import StepCounter from "@/components/common/step-counter"
import { Button } from "@/components/ui/button"
import { DrawerContent, DrawerFooter } from "@/components/ui/drawer"
import { Eat_Menu_Deal } from "@/lib/__generated__/graphql"
import { rappenToFrancs } from "@/lib/utils"
import { useMainStore } from "@/stores/main-store"

import { useDrawerContext } from "../../../../components/common/drawer/drawer-layout"
import { DrawerNavigationHeader } from "../../../../components/common/drawer/drawer-nav-header"
import {
  DealGroup,
  DealGroupSelectionItems,
} from "../../../shared-components/deal-group"

export function calculateDealTotal(
  selections: Record<string, DealGroupSelectionItems[] | undefined>,
  menuDeal: Eat_Menu_Deal
) {
  return (
    menuDeal.basePriceRappen +
    Object.values(selections)
      .flat()
      .filter((selectItem) => selectItem && selectItem.quantity > 0)
      .reduce(
        (acc, selectItem) =>
          acc +
          (menuDeal.groups
            .flatMap((s) => s.items)
            .find((item) => item.sku === selectItem?.sku)?.priceRappen ?? 0) *
            (selectItem?.quantity ?? 0),
        0
      )
  )
}

export function FocusDeal(props: {
  item: Eat_Menu_Deal
  restaurantId: string
}) {
  const setCart = useMainStore((state) => state.setCart)

  const drawer = useDrawerContext()

  const [activeIndex, setActiveIndex] = useState(0)

  const currentGroup = props.item.groups[activeIndex]

  if (!currentGroup) {
    throw new Error(
      `Group ${currentGroup} does not exists within deal ${props.item.name}`
    )
  }

  const [selections, setSelections] = useState<
    Record<string, DealGroupSelectionItems[] | undefined>
  >({})

  const groupSelection = useMemo(
    () =>
      Object.entries(selections).find(
        ([sku]) => sku === currentGroup?.sku
      )?.[1] ?? [],
    [selections, currentGroup?.sku]
  )

  const setGroupSelection = useCallback(
    (
      value:
        | DealGroupSelectionItems[]
        | SetStateAction<DealGroupSelectionItems[]>
    ) => {
      setSelections((selections) => ({
        ...selections,
        [currentGroup.sku]:
          typeof value === "function"
            ? value(selections[currentGroup.sku] ?? [])
            : value,
      }))
    },
    [currentGroup.sku]
  )

  //TODO: Better validation of different combinations
  const activeGroupValid =
    !currentGroup.mustSelectAnItem ||
    selections[currentGroup.sku]?.some(
      (selection) =>
        //Check that something is selected
        selection.quantity > 0 &&
        //And if so, modifiers has been selected for all instructions.
        selection.modifierGroups.length >=
          (currentGroup.items.find((item) => item.sku === selection.sku)
            ?.modifierGroups?.length ?? 0)
    )

  const next = async () => {
    if (!activeGroupValid) {
      return
    }

    const nextIndex = activeIndex + 1
    if (props.item.groups[nextIndex]) {
      return setActiveIndex((index) => index + 1)
    }
    const updatedCart = await addDealInCart({
      quantity: 1,
      restaurantId: props.restaurantId,
      dealSku: props.item.sku,
      groups: Object.entries(selections).map(([groupSku, selections]) => ({
        items:
          //TODO: This is wrong and it supply modifiers as well.
          selections?.map((selection) => ({
            sku: selection.sku,
            quantity: selection.quantity,
            modifierGroups: selection.modifierGroups,
          })) ?? [],
        sku: groupSku,
      })),
    })
    setCart(updatedCart)
    drawer.close()
  }

  const total = calculateDealTotal(selections, props.item)

  const onGoBack =
    activeIndex !== 0 && (() => setActiveIndex((index) => index - 1))

  return (
    <DrawerContent className="h-svh overflow-hidden" disableOverlay>
      <div className="overflow-y-scroll">
        <DrawerNavigationHeader
          title={props.item.name}
          close={activeIndex === 0}
          onBack={onGoBack}
        >
          <StepCounter
            className="mt-1.5"
            activeStep={activeIndex}
            numberOfSteps={props.item.groups.length}
          />
        </DrawerNavigationHeader>

        <div className="relative">
          <ContentSlider
            contentIndex={activeIndex}
            axis={"horizontal"}
            duration={500}
          >
            <DealGroup
              className="px-4"
              key={currentGroup.sku}
              group={currentGroup}
              selection={groupSelection}
              setSelection={setGroupSelection}
            />
          </ContentSlider>
        </div>
      </div>

      <DrawerFooter className="flex flex-row items-center justify-between border-t border-muted py-3">
        <Button
          variant={activeGroupValid ? "default" : "outline"}
          disabled={!activeGroupValid}
          className="flex h-10 min-w-32 flex-col text-sm"
          onClick={next}
        >
          {t("Menu - Item drawer - Button continue", "Continue")}
        </Button>
        <p className="text-lrg text-muted-foreground">
          {rappenToFrancs(total)} CHF
        </p>
      </DrawerFooter>
    </DrawerContent>
  )
}
