import { CheckIcon } from "lucide-react"
import { ComponentProps, Fragment, useMemo } from "react"

import { cn } from "@/lib/utils"

const duration = "duration-500"

export default function StepCounter({
  numberOfSteps,
  activeStep,
  ...props
}: {
  numberOfSteps: number
  activeStep: number
} & ComponentProps<"div">) {
  const iterator = useMemo(
    () => new Array(numberOfSteps).fill(null),
    [numberOfSteps]
  )
  return (
    <div
      {...props}
      className={cn("flex w-full items-center justify-center", props.className)}
    >
      {iterator.map((_s, index) => {
        const isComplete = index < activeStep
        const isCurrent = index === activeStep
        return (
          <Fragment key={index}>
            {index !== 0 && <Line filled={isComplete || isCurrent}></Line>}
            <div
              className={cn(
                "relative z-10 flex h-5 w-5 items-center rounded-full border-2 bg-background transition-all",
                isComplete || isCurrent ? "border-laaxred" : "border-border",
                duration
              )}
            >
              <div
                className={cn(
                  "absolute h-full w-full rounded-full bg-laaxred transition-transform ease-out",
                  isComplete ? "scale-105" : isCurrent ? "scale-50" : "scale-0",
                  duration
                )}
              />
              <CheckIcon
                strokeWidth={6}
                className={cn(
                  "absolute z-10 mt-[1.33px] h-full w-full text-background transition-transform ease-out",
                  isComplete ? "scale-75 delay-300" : "scale-0",
                  duration
                )}
              />
            </div>
          </Fragment>
        )
      })}
    </div>
  )
}

function Line(props: { filled: boolean }) {
  return (
    <div className="relative -mx-1 h-1 max-w-40 flex-1 bg-border">
      <div
        className={cn(
          "absolute h-full w-full origin-left bg-laaxred transition-transform ease-out",
          props.filled ? "scale-x-100" : "scale-x-0",
          duration
        )}
      ></div>
    </div>
  )
}
