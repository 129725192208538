import { t } from "i18next"

import OrderFooter from "@/components/common/order-footer"
import { useNavigate } from "@/lib/navigation"
import { useMainStore } from "@/stores/main-store"

import ItemsList from "./items-list"
import MenuHeader from "./menu-header"
import MenuWarningBanner from "./menu-warning-banner"
import SectionTabs from "./section-tabs"

export default function MenuPageClient(props: { restaurantId: string }) {
  const { restaurant, cart, menu } = useMainStore((state) => ({
    restaurant: state.restaurant,
    cart: state.cart,
    menu: state.menu,
  }))

  const navigate = useNavigate()

  if (!menu || !cart || !restaurant) {
    return null
  }

  const activeSections = menu.sections.filter(
    (section) => section.entries.length
  )

  return (
    <div className="flex flex-col">
      <div className="sticky top-9 mt-9 flex w-full flex-col gap-5 px-5">
        <MenuHeader
          restaurantName={restaurant.name}
          infoMarkdown={restaurant.infoMarkdown}
          isOpen={restaurant.orderingEnabled}
          openingText={"closes at 16:15"}
        />

        <MenuWarningBanner waitingTimeMinutes={restaurant.waitingTimeMinutes} />
      </div>

      <div className={"z-10 flex flex-col bg-white pt-1"}>
        <SectionTabs sections={activeSections} />

        <div className="flex flex-col gap-5">
          {activeSections.map((section, index) => (
            <ItemsList
              key={section.name}
              sectionId={section.id}
              sectionName={section.name}
              entries={section.entries}
              restaurantId={props.restaurantId}
              cart={cart}
              withHeader={index !== 0}
              withBottomPadding={index === activeSections.length - 1}
            />
          ))}
        </div>
      </div>
      <OrderFooter
        buttonText={t("Cart summary - Button - At menu page", "To the Cart")}
        buttonDisabled={cart.entries.length === 0}
        stepNumber={1}
        onButtonClick={() => navigate(`/${props.restaurantId}/cart`)}
      />
    </div>
  )
}
