import { toast } from "sonner"

import { graphql } from "@/lib/__generated__"
import {
  Eat_Cart_UpdateItem_Input,
  FullCartFragmentFragment,
} from "@/lib/__generated__/graphql"
import apolloClient from "@/lib/apollo/apollo-client"
import { getAnonId } from "@/lib/utils"

export async function updateItemInCart(input: Eat_Cart_UpdateItem_Input) {
  const response = await apolloClient.mutate({
    mutation: graphql(`
      mutation Eat_Cart_UpdateItem(
        $input: Eat_Cart_UpdateItem_Input!
        $anonId: ID!
      ) {
        Eat_Cart_UpdateItem(input: $input, anonId: $anonId) {
          cart {
            ...FullCartFragment
          }
        }
      }
    `),
    variables: {
      input,
      anonId: getAnonId(),
    },
  })
  const newCart = response.data?.Eat_Cart_UpdateItem.cart

  if (!newCart) {
    toast.error("An error occurred", {
      position: "top-center",
    })
    throw new Error("No cart returned from server")
  }
  return newCart as FullCartFragmentFragment
}
