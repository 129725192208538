import { SupportedPaymentType } from "@/lib/__generated__/graphql"

export const cartSteps = ["MENU", "CART", "DETAILS", "CHECKOUT"] as const

export type CartStep = (typeof cartSteps)[number]

export type PaymentConfig = {
  id: SupportedPaymentType
  name: string
  imageUrl: string
}

export const paymentsConfig: PaymentConfig[] = [
  {
    id: "CREDIT_CARD" as SupportedPaymentType,
    name: "Credit Card",
    imageUrl: "/payments/credit-card.png",
  },
  {
    id: "TWINT" as SupportedPaymentType,
    name: "TWINT",
    imageUrl: "/payments/twint.png",
  },
  {
    id: "GOOGLEPAY" as SupportedPaymentType,
    name: "Google Pay",
    imageUrl: "/payments/google-pay.png",
  },
  {
    id: "APPLEPAY" as SupportedPaymentType,
    name: "Apple Pay",
    imageUrl: "/payments/apple-pay.png",
  },
  {
    id: "POSTFINANCE" as SupportedPaymentType,
    name: "PostFinance",
    imageUrl: "/payments/post-finance.png",
  },
]
