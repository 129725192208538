export const i18nConfig = {
  projectId: "f5c34c46-a115-490c-9052-efc2c168babe",
  defaultLocale: "en",
  locales: ["en", "de"],
  defaultNS: "eat-web",
  cookieName: "i18next",
} as const

export type Locale = (typeof i18nConfig)["locales"][number]

export function removeLangFromPath(pathname: string) {
  for (const locale of i18nConfig.locales) {
    const localePath = `/${locale}`
    if (pathname.startsWith(localePath + "/") || pathname === localePath) {
      const newPath = pathname.substring(localePath.length)
      if (newPath === "") return "/"
      return newPath
    }
  }
  return pathname
}

export function getOptions(
  lng: Locale = i18nConfig.defaultLocale,
  ns = i18nConfig.defaultNS
) {
  return {
    // debug: true,
    supportedLngs: i18nConfig.locales,
    fallbackLng: i18nConfig.defaultLocale,
    lng,
    fallbackNS: i18nConfig.defaultNS,
    defaultNS: i18nConfig.defaultNS,
    ns,
  }
}
