import { toast } from "sonner"

import { graphql } from "@/lib/__generated__"
import {
  Eat_Cart_AddDeal_Input,
  FullCartFragmentFragment,
} from "@/lib/__generated__/graphql"
import apolloClient from "@/lib/apollo/apollo-client"
import { getAnonId } from "@/lib/utils"

export async function addDealInCart(input: Eat_Cart_AddDeal_Input) {
  const response = await apolloClient.mutate({
    mutation: graphql(`
      mutation Eat_Cart_AddDeal($input: Eat_Cart_AddDeal_Input!, $anonId: ID) {
        Eat_Cart_AddDeal(input: $input, anonId: $anonId) {
          cart {
            ...FullCartFragment
          }
        }
      }
    `),
    variables: {
      input: { ...input, quantity: 1 },
      anonId: getAnonId(),
    },
  })

  const newCart = response.data?.Eat_Cart_AddDeal.cart

  if (!newCart) {
    toast.error("An error occurred", {
      position: "top-center",
    })
    throw new Error(
      response.errors?.map((err) => err.message).join(", ") ??
        "Could not update cart"
    )
  }
  return newCart as FullCartFragmentFragment
}
