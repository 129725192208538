import { useParams } from "react-router-dom"

import { DrawerLayout } from "@/components/common/drawer/drawer-layout"

import FocusEntry from "./components/focus/focus-entry"
import MenuPageClient from "./components/page-client"

export default function MenuPage() {
  const { restaurantId, itemId } = useParams()
  return (
    <DrawerLayout
      drawer={
        itemId ? (
          <FocusEntry itemId={itemId} restaurantId={restaurantId!} />
        ) : null
      }
    >
      <MenuPageClient restaurantId={restaurantId!} />
    </DrawerLayout>
  )
}
