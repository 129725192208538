import { Minus, Plus, Trash2 } from "lucide-react"

import { ContentSlider } from "@/components/common/content-slider"
import { cn } from "@/lib/utils"

import { RoundButtonFilled, RoundButtonOutline } from "./round-button"

export function QuantitySelector(props: {
  quantity: number
  maxQuantity?: number
  onIncrement(): void
  onDecrement(): void
  trashIconsSingleItem?: boolean
}) {
  const isMaxQuantity =
    !!props.maxQuantity && props.quantity >= props.maxQuantity
  return (
    <div className="flex w-full items-end justify-between">
      <div className="flex items-center gap-2">
        <RoundButtonOutline
          disabled={props.quantity <= 0}
          className={props.quantity === 0 ? "opacity-0" : "opacity-100"}
          onClick={props.onDecrement}
        >
          {props.trashIconsSingleItem && props.quantity == 1 ? (
            <Trash2 className="size-4" />
          ) : (
            <Minus className="size-4 stroke-[4px]" />
          )}
        </RoundButtonOutline>

        <div
          className={cn(
            "min-w-3.5 overflow-hidden text-center leading-7 transition-colors duration-100"
          )}
        >
          <ContentSlider axis="vertical" contentIndex={props.quantity}>
            <span
              className={cn(
                "block",
                props.quantity > 0 ? "text-laaxred" : "text-primary"
              )}
            >
              {props.quantity}
            </span>
          </ContentSlider>
        </div>

        <RoundButtonFilled
          className={cn(isMaxQuantity && "opacity-0")}
          onClick={props.onIncrement}
          disabled={isMaxQuantity}
        >
          <Plus className="size-4 stroke-[4px]" />
        </RoundButtonFilled>
      </div>
    </div>
  )
}
