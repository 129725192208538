import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { Button } from "@/components/ui/button"
import { auth } from "@/lib/auth"
import { Link } from "@/lib/navigation"

export function LoginPage() {
  const [status, setStatus] = useState<
    "loading" | "authenticated" | "unauthenticated"
  >("loading")

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const login: Promise<any> = searchParams.get("code")
      ? auth.handleRedirectCallback()
      : Promise.resolve()
    login.then(() => {
      auth.isAuthenticated().then((isLoggedIn) => {
        isLoggedIn && auth.getTokenSilently()
        setStatus(isLoggedIn ? "authenticated" : "unauthenticated")
      })
      setSearchParams()
    })
  }, [])

  if (status === "loading") {
    return "Loading..."
  }

  if (status === "authenticated") {
    return (
      <div className="flex flex-col gap-1">
        <p>Authenticated</p>
        <Button
          onClick={async () => {
            await auth.logout()
          }}
        >
          Sign out
        </Button>
        <Button variant="secondary" asChild>
          <Link to="/">Go to menu</Link>
        </Button>
      </div>
    )
  }

  if (status === "unauthenticated") {
    return (
      <div className="flex flex-col gap-1">
        <Button
          onClick={async () => {
            await auth.loginWithRedirect({
              authorizationParams: {
                redirect_uri: `${window.location.origin}/login`,
              },
            })
          }}
        >
          Login
        </Button>
        <Button variant="secondary" asChild>
          <Link to="/">Go to menu</Link>
        </Button>
      </div>
    )
  }

  return null
}
