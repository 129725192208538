import { RadioGroupItem } from "@/components/ui/radio-group"
import { SupportedPaymentType } from "@/lib/__generated__/graphql"
import { PaymentConfig } from "@/types/types"

export default function PaymentSelectItem(props: {
  paymentConfig: PaymentConfig
  setIsPaymentDrawerOpen(value: boolean): void
  setSelectedPaymentType(paymentType: SupportedPaymentType): void
}) {
  return (
    <div className="flex items-center justify-between">
      <button
        className="flex w-full items-center gap-5"
        onClick={() => {
          props.setSelectedPaymentType(props.paymentConfig.id)
          props.setIsPaymentDrawerOpen(false)
        }}
      >
        <img
          src={props.paymentConfig.imageUrl}
          alt={props.paymentConfig.name}
          className="h-6"
        />
        <p className="text-sm">{props.paymentConfig.name}</p>
      </button>

      <RadioGroupItem
        value={props.paymentConfig.id}
        id={props.paymentConfig.id}
      />
    </div>
  )
}
