import { Eat_Cart_Entry } from "@/lib/__generated__/graphql"
import { rappenToFrancs } from "@/lib/utils"

export default function CartEntry(props: { cartEntry: Eat_Cart_Entry }) {
  return (
    <div className="flex items-center justify-between">
      <p className="text-sm font-medium">
        <span className="text-red-700">{props.cartEntry.quantity + "x"}</span>{" "}
        {props.cartEntry.__typename === "Eat_Cart_Deal"
          ? props.cartEntry.menuDeal.name
          : props.cartEntry.menuItem.name}
      </p>

      <p className="text-sm font-medium">
        {rappenToFrancs(props.cartEntry.priceRappen)}{" "}
        <span className="text-xs font-normal">CHF</span>
      </p>
    </div>
  )
}
