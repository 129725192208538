import { NavLink, useLocation } from "react-router-dom"

import { Eat_Cart, Eat_Menu_Entry } from "@/lib/__generated__/graphql"
import { cn, rappenToFrancs } from "@/lib/utils"

import { ListItem } from "../../../components/common/list-item"

export default function ItemsList(props: {
  sectionId: string
  sectionName: string
  entries: Eat_Menu_Entry[]
  restaurantId: string
  cart: Eat_Cart
  withHeader: boolean
  withBottomPadding: boolean
}) {
  const pathname = useLocation().pathname
  return (
    <div id={props.sectionId}>
      {props.withHeader && (
        <div className="mb-3 bg-muted px-5 py-2">
          <p className="text-sm font-medium">{props.sectionName}</p>
        </div>
      )}

      {props.entries.map((item, index) => {
        const quantityInCart = props.cart.entries
          .filter((cartEntry) => cartEntry.sku === item.sku)
          .reduce((acc, cartEntry) => acc + cartEntry.quantity, 0)

        return (
          <NavLink
            key={item.sku}
            className={({ isPending }) =>
              cn(
                "block transition-transform duration-200",
                isPending && "animate-press-in"
              )
            }
            to={`${pathname}/item/${item.sku}`}
            preventScrollReset
          >
            <ListItem
              {...item}
              className="px-5"
              name={
                <>
                  <span className="text-laaxred">
                    {quantityInCart ? `${quantityInCart}x ` : ""}
                  </span>
                  {item.name}
                </>
              }
              priceText={
                item.__typename === "Eat_Menu_Deal"
                  ? rappenToFrancs(item.basePriceRappen) + " CHF (base price)"
                  : rappenToFrancs(item.priceRappen) + " CHF"
              }
            />
          </NavLink>
        )
      })}
      {props.withBottomPadding && (
        <div
          id="section-padder"
          style={{
            height: `max(0px, calc(76svh - ${props.entries.length * 92}px))`,
          }}
        />
      )}
    </div>
  )
}
