import { toast } from "sonner"

import { Button } from "@/components/ui/button"
import { useTranslation } from "@/i18n/client"
import { useNavigate } from "@/lib/navigation"
import { useMainStore } from "@/stores/main-store"

export function ViewMenuButton(props: { restaurantId: string }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const tableNumber = useMainStore((state) => state.tableNumber)

  return (
    <Button
      className="w-full bg-laaxred hover:bg-laaxreddark"
      onClick={() => {
        if (tableNumber === 0) {
          toast.error(
            t(
              "Toast - Scan QR code to get your table number",
              "Please scan a QR code to get your table number"
            ),
            {
              position: "top-center",
            }
          )
          return
        }

        navigate(`/${props.restaurantId}/menu`)
      }}
    >
      {t("Home - View menu button", "View the menu")}
    </Button>
  )
}
