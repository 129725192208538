import { graphql } from "@/lib/__generated__"
import { FullCartFragmentFragment } from "@/lib/__generated__/graphql"
import apolloClient from "@/lib/apollo/apollo-client"
import { getAnonId } from "@/lib/utils"

export async function getCart(
  restaurantId: string
): Promise<FullCartFragmentFragment> {
  const { data } = await apolloClient.query({
    query: graphql(`
      query Eat_Cart_GetCartForRestaurant(
        $input: Eat_Cart_GetCartForRestaurant_Input!
        $anonId: ID
      ) {
        Eat_Cart_GetCartForRestaurant(input: $input, anonId: $anonId) {
          cart {
            ...FullCartFragment
          }
        }
      }
    `),
    variables: {
      input: {
        restaurantId,
      },
      anonId: getAnonId(),
    },
    fetchPolicy: "no-cache",
  })

  return data.Eat_Cart_GetCartForRestaurant.cart as FullCartFragmentFragment
}
