import { MapPin } from "lucide-react"

import { useTranslation } from "@/i18n/client"
import { useMainStore } from "@/stores/main-store"

import { ViewMenuButton } from "./view-menu-button"

export function Welcome(props: { restaurantId: string }) {
  const { t } = useTranslation()

  const { tableNumber, restaurant } = useMainStore((state) => ({
    tableNumber: state.tableNumber,
    restaurant: state.restaurant,
  }))

  if (!restaurant) {
    return null
  }

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="flex flex-col items-center">
        <p className="mb-5 text-3xl font-semibold">{restaurant.name}</p>

        <div className="flex flex-col items-center gap-1 text-sm">
          <div className="flex items-center gap-1 text-muted-foreground">
            <MapPin className="size-4" />
            <p>Via Nova 80, 7017 Flims Dorf</p>
          </div>

          <div className="flex items-center gap-1">
            {restaurant.orderingEnabled ? (
              <p className="text-green-500">{t("OPEN", "OPEN")}</p>
            ) : (
              <p className="text-red-500">{t("CLOSED", "CLOSED")}</p>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center gap-1 text-sm">
        <p className="text-muted-foreground">
          {t(
            "Home - Bring to your table",
            "We will gladly bring your order to your table"
          )}
        </p>
        <div className="flex items-center gap-1">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_582_46087)">
              <path d="M4.5 7V17ZM1.5 7H23.5ZM4.5 10H20.5ZM20.5 7V17Z" />
              <path
                d="M4.5 7V17M1.5 7H23.5M4.5 10H20.5M20.5 7V17"
                stroke="#000"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_582_46087">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0.5)"
                />
              </clipPath>
            </defs>
          </svg>

          <p>
            {t("Home - Table", "Table")} {tableNumber}
          </p>
        </div>
      </div>

      <div className="flex flex-col items-center gap-3">
        <ViewMenuButton restaurantId={props.restaurantId} />

        <p className="text-xs text-muted-foreground">
          {t("Home - Order via LAAX App 1", "You can also order via the")}{" "}
          <a
            href="https://laax.com/laax-app"
            className="text-blue-500 underline"
          >
            {t("Home - Order via LAAX App 2", "LAAX App")}
          </a>{" "}
          {t("Home - Order via LAAX App 3", ".")}
        </p>
      </div>
    </div>
  )
}
