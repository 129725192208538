import { graphql } from "@/lib/__generated__"
import {
  EatAdmin_Order_GetOrder_Input,
  Eat_Order_Table,
} from "@/lib/__generated__/graphql"
import apolloClient from "@/lib/apollo/apollo-client"

export async function getOrder(
  input: EatAdmin_Order_GetOrder_Input
): Promise<Eat_Order_Table> {
  const { data } = await apolloClient.query({
    query: graphql(`
      query Eat_Order_GetOrder($input: Eat_Order_GetOrder_Input!) {
        Eat_Order_GetOrder(input: $input) {
          order {
            __typename
            ... on Eat_Order_Table {
              __typename
              accountIdentifier
              anonId
              commentOnReceipt
              createdAt
              email
              id
              items {
                modifiers {
                  groupName
                  modifierId
                  modifierName
                }
                name
                pricePoints
                priceRappen
                quantity
                sku
                subItems {
                  name
                  quantity
                  sku
                  modifiers {
                    groupName
                    modifierId
                    modifierName
                  }
                }
              }
              itemsTotalPoints
              itemsTotalRappen
              nameOnReceipt
              orderNumber
              orderTotalPoints
              orderTotalRappen
              payment {
                id
              }
              restaurantId
              restaurantName
              tableNumber
              tableOrderStatus
              tipRappen
              userId
            }
          }
        }
      }
    `),
    variables: {
      input,
    },
    fetchPolicy: "no-cache",
  })

  const order = data.Eat_Order_GetOrder.order

  if (!order) {
    throw new Error("Order not found")
  }

  if (order.__typename !== "Eat_Order_Table") {
    throw new Error("Order is not a table order")
  }

  return order
}
