import { useEffect, useState } from "react"

export function useDelayedValue<TValue>(value: TValue, delay: number) {
  const [prev, setPrev] = useState(value)
  useEffect(() => {
    const timeout = setTimeout(() => setPrev(value), delay)
    return () => {
      clearTimeout(timeout)
    }
  }, [value, delay])
  return prev
}

export function useLocalStorageState<T>(
  defaultValue: T,
  localStorageKey: string,
  validator = (storageValue: T) => true
) {
  const state = useState(() => {
    const storageValue = localStorage.getItem(localStorageKey)
    try {
      const parsedValue = storageValue && (JSON.parse(storageValue) as T)
      return parsedValue && validator(parsedValue) ? parsedValue : defaultValue
    } catch {
      return defaultValue
    }
  })

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(state[0]))
  }, [state[0]])

  return state
}

export function useImagePreload(...imagePaths: string[]) {
  useEffect(() => {
    imagePaths.forEach((imagePath) => {
      //Create a new imageobject in memory with source set to force image load
      new Image().src = imagePath
    })
  }, [...imagePaths])
}
