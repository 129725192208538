import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { toast } from "sonner"

import { getCart } from "@/actions/cart/get-cart"
import { getMenuForRestaurant } from "@/actions/get-menu"
import { getRestaurant } from "@/actions/get-restaurant"
import { useTranslation } from "@/i18n/client"
import { useNavigate } from "@/lib/navigation"
import { useMainStore } from "@/stores/main-store"

export function Loader(props: { restaurantId: string }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { setRestaurant, setMenu, setCart, setTableNumber } = useMainStore(
    (state) => ({
      setRestaurant: state.setRestaurant,
      setMenu: state.setMenu,
      setCart: state.setCart,
      setTableNumber: state.setTableNumber,
    })
  )

  useEffect(() => {
    const load = async () => {
      const [menu, restaurant, cart] = await Promise.all([
        getMenuForRestaurant(props.restaurantId),
        getRestaurant(props.restaurantId),
        getCart(props.restaurantId),
      ])

      if (restaurant === null) {
        toast.error(t("Toast - Restaurant not found", "Restaurant not found"))
        return
      }
      setRestaurant(restaurant)

      if (menu == null) {
        navigate("/")
        return
      }

      setMenu(menu)

      if (!cart) {
        toast.error(t("Toast - Cart not found", "Cart not found"))
        return
      }

      setCart(cart)
    }

    load()
  }, [])

  useEffect(() => {
    const tableNumber = searchParams.get("tableNumber")
    if (!tableNumber) {
      return
    }

    const tableNumberInt = parseInt(tableNumber)
    setTableNumber(tableNumberInt)
  }, [searchParams])

  return null
}
