import { graphql } from "@/lib/__generated__"
import {
  Eat_Order_CheckWebPayment_Input,
  Eat_Order_CheckWebPayment_Response,
} from "@/lib/__generated__/graphql"
import apolloClient from "@/lib/apollo/apollo-client"

export async function checkWebPayment(
  input: Eat_Order_CheckWebPayment_Input
): Promise<Eat_Order_CheckWebPayment_Response | undefined> {
  const { data } = await apolloClient.query({
    query: graphql(`
      query Eat_Order_CheckWebPayment(
        $input: Eat_Order_CheckWebPayment_Input!
      ) {
        Eat_Order_CheckWebPayment(input: $input) {
          message
          status
        }
      }
    `),
    variables: {
      input,
    },
    fetchPolicy: "no-cache",
  })

  return data?.Eat_Order_CheckWebPayment
}
