import cn from "clsx"
import { useEffect, useRef, useState } from "react"

import { Eat_Menu_Section } from "@/lib/__generated__/graphql"

export default function SectionTabs(props: { sections: Eat_Menu_Section[] }) {
  const [selectedSectionId, setSelectedSectionId] = useState(
    props.sections[0]?.id
  )
  const containerRef = useRef<HTMLDivElement>(null)

  const togglerRef = useRef<HTMLDivElement>(null)

  //Track when section are scrolled into view and select them.
  useEffect(() => {
    const container = containerRef.current
    if (container == null) {
      return
    }
    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setSelectedSectionId(entry.target.id)
          }
        }),
      {
        rootMargin: `-${container.offsetHeight}px 0% -${window.innerHeight - container.offsetHeight}px 0%`,
      }
    )
    props.sections.forEach((section) => {
      const element = document.getElementById(section.id)
      element && observer.observe(element)
    })
    return observer.disconnect.bind(observer)
  }, [props.sections])

  //Scroll to selected tab and mark it
  useEffect(() => {
    const heading = document.getElementById(
      `header-section-${selectedSectionId}`
    )

    if (!heading || !togglerRef.current) {
      return
    }

    containerRef.current?.scrollTo({
      left: heading?.offsetLeft - 15,
      behavior: "smooth",
    })
    togglerRef.current.style.transform = `translateX(${heading.offsetLeft}px) scaleX(${heading.clientWidth})`
  }, [selectedSectionId])

  return (
    <div
      ref={containerRef}
      className={cn(
        "no-scrollbar sticky top-9 z-10 overflow-y-hidden overflow-x-scroll bg-white pt-5 [box-shadow:inset_0_-1.5px_0_0_hsl(var(--border))]"
      )}
    >
      <div className="flex w-full">
        {props.sections.map((section) => (
          <button
            key={section.id}
            className={cn(
              "origin-bottom whitespace-nowrap px-3 py-2 text-sm transition-all duration-300",
              section.id === selectedSectionId
                ? "text-laaxred opacity-100"
                : "opacity-70"
            )}
            onClick={() => {
              const selectedSectionDiv = document.getElementById(section.id)
              if (selectedSectionDiv) {
                scrollTo({
                  top:
                    selectedSectionDiv?.offsetTop -
                    (containerRef.current?.offsetHeight ?? 0),
                  behavior: "instant",
                })
                setSelectedSectionId(section.id)
              }
            }}
          >
            <div id={`header-section-${section.id}`}>{section.name}</div>
          </button>
        ))}
      </div>

      <div
        ref={togglerRef}
        className={cn(
          "absolute bottom-0 left-0 h-[1.5px] w-[1px] origin-bottom-left bg-laaxred transition-all duration-300"
        )}
      />
    </div>
  )
}
