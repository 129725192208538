import { toast } from "sonner"

import { graphql } from "@/lib/__generated__"
import {
  Eat_Cart_AddItem_Input,
  FullCartFragmentFragment,
} from "@/lib/__generated__/graphql"
import apolloClient from "@/lib/apollo/apollo-client"
import { getAnonId } from "@/lib/utils"

export async function addItemInCart(input: Eat_Cart_AddItem_Input) {
  const response = await apolloClient.mutate({
    mutation: graphql(`
      mutation Eat_Cart_AddItem($input: Eat_Cart_AddItem_Input!, $anonId: ID!) {
        Eat_Cart_AddItem(input: $input, anonId: $anonId) {
          cart {
            ...FullCartFragment
          }
        }
      }
    `),
    variables: {
      input,
      anonId: getAnonId(),
    },
  })

  const newCart = response.data?.Eat_Cart_AddItem.cart
  if (!newCart) {
    toast.error("An error occurred", {
      position: "top-center",
    })
    throw new Error("No cart returned from addItem to cart")
  }

  return newCart as FullCartFragmentFragment
}
