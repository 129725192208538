import { useParams } from "react-router-dom"

import { DrawerLayout } from "@/components/common/drawer/drawer-layout"
import { useParamsRequired } from "@/lib/navigation"

import CartList from "./components/cart-list"
import { FocusEntry } from "./components/focus-entry"

export default function CartPage() {
  const { itemId } = useParams()
  const { restaurantId } = useParamsRequired("restaurantId")
  return (
    <DrawerLayout
      drawer={
        itemId ? <FocusEntry id={itemId} restaurantId={restaurantId} /> : null
      }
    >
      <CartList restaurantId={restaurantId} />
    </DrawerLayout>
  )
}
