import { User } from "lucide-react"

import OrderFooter from "@/components/common/order-footer"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { useTranslation } from "@/i18n/client"
import { useNavigate, useParamsRequired } from "@/lib/navigation"
import { useMainStore } from "@/stores/main-store"

export default function OrderDetailsPage() {
  const { t } = useTranslation()
  const { restaurantId } = useParamsRequired("restaurantId")

  const { cart, menu } = useMainStore((state) => ({
    cart: state.cart,
    menu: state.menu,
  }))

  const [nameOnReceipt, setNameOnReceipt] = useMainStore((state) => [
    state.nameOnReceipt,
    state.setNameOnReceipt,
  ])

  const [comments, setComments] = useMainStore((state) => [
    state.comments,
    state.setComments,
  ])

  const navigate = useNavigate()

  if (menu === null || cart === null) {
    return null
  }

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault()
        if (!nameOnReceipt) {
          return
        }
        navigate(`/${restaurantId}/checkout`)
      }}
    >
      <main className="mt-14 flex flex-col gap-5">
        <div className="flex flex-col gap-5 px-5">
          <p className="text-sm">
            {t(
              "Details - Title",
              "This information will help you get your order to the right place."
            )}
          </p>

          <div className="flex flex-col gap-1">
            <p className="text-sm font-semibold">
              {t("Details - Your details label", "Your details*")}
            </p>

            <div className="flex w-full items-center gap-3 rounded-md border px-2 text-sm">
              <User className="size-4 shrink-0" />
              <Input
                required
                className="border-none px-0 focus-visible:ring-0 focus-visible:ring-offset-0"
                value={nameOnReceipt}
                onChange={(e) => {
                  setNameOnReceipt(e.target.value)
                }}
                placeholder={t("Details - Your name placeholder", "Your name")}
              />
            </div>
          </div>

          <Textarea
            className="h-24 rounded-md p-2 text-sm focus-visible:ring-0 focus-visible:ring-offset-0"
            placeholder={t(
              "Details - Comments placeholder",
              "Comments (optional)"
            )}
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />
        </div>
      </main>
      <OrderFooter
        buttonText={t("Cart summary - Button - At details page", "To checkout")}
        stepNumber={3}
        onButtonClick={() => {}}
      />
    </form>
  )
}
