import { graphql } from "@/lib/__generated__"
import { MutationEat_Order_StartAnonWebPaymentArgs } from "@/lib/__generated__/graphql"
import apolloClient from "@/lib/apollo/apollo-client"

export async function startWebPayment(
  variables: MutationEat_Order_StartAnonWebPaymentArgs
): Promise<{ paymentPageUrl?: string }> {
  const { data } = await apolloClient.mutate({
    mutation: graphql(`
      mutation Eat_Order_StartAnonWebPayment(
        $input: Eat_Order_StartAnonWebPayment_Input!
      ) {
        Eat_Order_StartAnonWebPayment(input: $input) {
          paymentPageUrl
        }
      }
    `),
    variables,
  })

  return { paymentPageUrl: data?.Eat_Order_StartAnonWebPayment.paymentPageUrl }
}
