import { Info } from "lucide-react"
import { useState } from "react"

import { DrawerNavigationHeader } from "@/components/common/drawer/drawer-nav-header"
import { buttonVariants } from "@/components/ui/button"
import { Drawer, DrawerContent, DrawerOverlay } from "@/components/ui/drawer"
import { useTranslation } from "@/i18n/client"
import { Link } from "@/lib/navigation"
import { cn } from "@/lib/utils"

export function InstructionSteps() {
  const { t } = useTranslation()

  const [open, setIsOpen] = useState(false)

  return (
    <div className="flex items-center gap-5">
      <Drawer open={open} onClose={() => setIsOpen(false)}>
        <a onClick={() => setIsOpen(true)} className="text-xs text-blue-600">
          <Info className="h-5 w-5" />
          <p>{t("Menu - Header - Info", "info")}</p>
        </a>
        <DrawerContent className="h-[90%] px-3" disableOverlay>
          <DrawerNavigationHeader
            title={t("Instruction steps - Title", "How does it work?")}
            close={() => setIsOpen(false)}
          />
          <div className="flex flex-1 flex-col gap-8 overflow-y-scroll pt-3">
            <InstructionStep
              step={1}
              icon={"/icons/phone.svg"}
              description={t(
                "Instruction steps - Step 1 - Description",
                "Scan and order on your table"
              )}
            />

            <InstructionStep
              step={2}
              icon={"/icons/select-items.svg"}
              description={t(
                "Instruction steps - Step 2 - Description",
                "Select your food and add to the cart"
              )}
            />

            <InstructionStep
              step={3}
              icon={"/icons/pay.svg"}
              description={t(
                "Instruction steps - Step 3 - Description",
                "Pay your order and save the receipt"
              )}
            />

            <InstructionStep
              step={4}
              icon={"/icons/waiter.svg"}
              description={t(
                "Instruction steps - Step 4 - Description",
                "We're happy to bring your food to you!"
              )}
            />
          </div>
          <div className="flex flex-col gap-6 py-4">
            {/* <p className="px-2 text-sm">
              {t(
                "Instruction steps - Footer text 1",
                "In the meantime, you can download the"
              )}{" "}
              <Link
                href="https://laax.com/laax-app"
                target="_blank"
                className="text-blue-500 underline"
              >
                {t("Instruction steps - Footer text 2 (link)", "LAAX APP")}
              </Link>{" "}
              {t(
                "Instruction steps - Footer text 3",
                "and collect the loyalty points on your order."
              )}
            </p> */}

            <Link
              to="https://laax.com/laax-app"
              className={cn(
                buttonVariants(),
                "mx-auto flex w-64 items-center gap-1 bg-red-600"
              )}
            >
              <img
                src="/icons/laax-app-icon.svg"
                alt="step explaining mobile ordering"
                className="h-6 object-contain"
              />
              <p>
                {t("Instruction steps - Footer button", "Get the LAAX APP")}
              </p>
            </Link>
          </div>
        </DrawerContent>
        <DrawerOverlay
          onClick={() => setIsOpen(false)}
          style={{ position: "fixed", inset: 0, zIndex: 200 }}
        />
      </Drawer>
    </div>
  )
}

function InstructionStep(props: {
  step: number
  icon: string
  description: string
}) {
  return (
    <div className="flex items-center gap-3">
      <div className="relative rounded-lg border-[0.95px] border-black p-2">
        <img src={props.icon} alt={""} className="h-16 w-16 object-contain" />
        <div className="absolute -right-3 -top-3 flex h-6 w-6 items-center justify-center rounded-full bg-black font-bold text-white">
          <span className="mt-0.5 block tabular-nums">{props.step}</span>
        </div>
      </div>

      <p>{props.description}</p>
    </div>
  )
}
